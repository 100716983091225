const config = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "cytodynamic-imageseries-upload"
  },
  apiGateway: {
    // blueprint-mockup-api (blueprint-python-api2 in WSL)
    REGION: "us-east-1",
    URL: "https://cn1yokul62.execute-api.us-east-1.amazonaws.com/dev",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_W77D1U20f",
    APP_CLIENT_ID: "3bv1m14eungvl4332jeu7mo3gi",
    IDENTITY_POOL_ID: "us-east-1:53cdec59-06aa-424d-9363-14cfc622d2ae",
  },
};

export default config;