import React from "react";
// import Button from "react-bootstrap/Button";
import {Image} from 'react-bootstrap';
import styled from 'styled-components';
import {ReactComponent as UpArrow} from "../../assets/arrow-up.svg";
import {ReactComponent as DownArrow} from "../../assets/arrow-down.svg";


const ImageCell = styled.td`
  width: 400px
`;

const TableContainer = styled.div`
  height: 800px;
  overflow: scroll;

`;

const DataTable = styled.table`
  width: 100%;
  height: 800px;
`;

// const TableHead = styled.thead`
//   width: 100%;
//   display: flex;
//   flex-direction: row;
// `;

// const TableRow = styled.tr`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-around;
// `;

const TableBody = styled.tbody`
  overflow: scroll;
  height: 800px;
`;

const TableHeaders = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const Header1 = styled.h1`
  font-family: Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
   "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-weight: 650;
  color:rgb(5,15,25);
  line-height: 1.3;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 200px;
`;

const Header2 = styled.h1`
  font-family: Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
   "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-weight: 650;
  color:rgb(5,15,25);
  line-height: 1.3;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 230px;
`;

const Header3 = styled.h2`
  font-family: Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
   "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-weight: 650;
  color:rgb(5,15,25);
  line-height: 1.3;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 230px;
`;

const AreaCell = styled.td`
  text-align: center;
  border: 1px solid black;
  width: 300px
`;

const DifferenceCell = styled.td`
  border: 1px solid black;
  display: flex;
  height: 400px;
  flex-direction: column;
  justify-content: center;

`;

const DifferenceCellTxt = styled.h2`
  text-align: center;
`;

export default function OutputTable({areaOutput}) {

  function compare(a,b) {
    const indexA = a.index;
    const indexB = b.index;
    let comparison = 0;
    if(indexA > indexB) {
      comparison = 1;
    } else if (indexA < indexB) {
      comparison = -1;
    }
    return comparison;
  }

  const diff = (A) => { return A.slice(1).map( (item,index) => { return item - A[index] } )}

  if (areaOutput) {
    areaOutput = areaOutput.sort(compare)
    let result = areaOutput.map(({ areaSum }) => areaSum)
    var difference = diff(result)
    console.log(typeof(areaOutput))
    console.log(difference)
  }



  var renderRow = areaOutput.map(function(item, index){
    // const newImage = await s3getImage(scanId, areaOutput.index);
    return(
      <>
        <tr key={index}>
          <ImageCell>
            { item.url && <Image src={item.url} fluid/> }
          </ImageCell>
          <AreaCell>
            {item.areaSum+' Pixels'}
          </AreaCell>
          <DifferenceCell>
            <DifferenceCellTxt>{difference[index]}</DifferenceCellTxt>
            {difference[index] > 0 ? <UpArrow width='100px' stroke='green'/> : <DownArrow width='100px' stroke='red'/>}
          </DifferenceCell>
        </tr>
      </>
    )
  })

  return (
    <>
    <TableHeaders>
      <Header1>Image</Header1>
      <Header2>Area Output</Header2>
      <Header3>Difference</Header3>
    </TableHeaders>
    <TableContainer>
      <DataTable>
        <TableBody>
          {areaOutput && renderRow}
        </TableBody>
      </DataTable>
    </TableContainer>
    </>
  )
}
