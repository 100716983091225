import React, {useState, useEffect} from "react";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import "./Home.css";

export default function Home() {
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        // Put logged in homepage data here
      } catch(e) {
        onError(e);
      }
      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function renderLander() {
    console.log(isLoading);   // Just did this to get rid of error
    return (
      <div className="lander">
        <h1>CytoDynamic Solutions</h1>
        <p className="text-muted">Please Login</p>
      </div>
    );
  }
  function renderHome() {
    return (
      <div className="scandata">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">CytoDynamic</h2>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderHome() : renderLander()}
    </div>
  );
}