import React, {useRef, useState} from "react";
import Form from "react-bootstrap/Form";
import {API} from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import OutputTable from "../components/AreaDetection/OutputTable";
import { s3Upload } from "../libs/awsLib";
import { onError } from "../libs/errorLib";

import "./AreaDetection.css";

export default function AreaDetection() {
  const file = useRef(null);
  const [scanId, setScanId]         = useState("");
  // const [unzipIndex, setUnzipIndex] = useState(0);
  const [isLoading, setIsLoading]   = useState(false);

  const [areaOutput, setAreaOutput] = useState([]);

  function appendAreaOutput(response){
    setAreaOutput(arr => [...arr, response])
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
    console.log(file.current.name)
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const params = {
      queryStringParameters: {
        scanId        : scanId,
        filename      : file.current.name,
        unzipIndex    : 0
      }
    }
    setIsLoading(true);

    try {
      const attachment = file.current ? await s3Upload(scanId, file.current) : null;
      console.log(attachment)
      const zipSize   = await API.get('cytodynamic-python-api2', '/checkZipSize', params)
      // Unpack zip package
      for (let i=0; i < zipSize; i++){
        params['queryStringParameters']['unzipIndex'] = i;
        API.get('cytodynamic-python-api2', '/runSingleScan', params)
        .then(response => appendAreaOutput(response))
        console.log(i)
      }

      console.log('waiting')
      await sleep(5000)
      console.log(areaOutput.length)
      console.log(areaOutput)
      setIsLoading(false);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  return (
    <div className="Home">
      <div className="lander">
        <h1>CytoDynamic</h1>
        <p className="text-muted">MVP</p>
      </div>

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="scanIdInput">
          <Form.Label><b>Input Scan Id</b></Form.Label>
          <Form.Control
            value={scanId}
            as="input"
            onChange= {(e) => setScanId(e.target.value)}/>
        </Form.Group>
        <Form.Group controlId="file">
          <Form.Label><b>JPG Zip File Upload</b></Form.Label>
          <Form.Control onChange={handleFileChange} type="file"/>
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          size="lg"
          variant="primary"
          isLoading={isLoading}>
          Run Scan
        </LoaderButton>
      </Form>
      <br/>
      <br/>
      <OutputTable areaOutput={areaOutput}/>
    </div>
  );
}